import React from "react"
import SEO from '../components/seo'
import Header from "../components/Header"
import Footer from "../components/Footer"

const JasonPage = () => {
  
  return (
    <div>
      <SEO
         title="Product Skills"
      />
       <Header />
       <div className="section-container content">
       <span role="img" aria-label="hey">👋 </span>Hey, I'm Jason. Nothing going on here yet, but I imagine one day I'll make this page look nice and shiny.
       </div>
       <Footer />
    </div>
  )
}

export default JasonPage